import {
    GetGamesResponse,
    iUsePlayGames,
    PlayGamesLoader,
    ShowProvidersResponse,
    StartGameSessionResponse
} from "./playGamesModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLanguage } from "../localisation/useLanguage";
import { useCallback } from "react";
import {
    setGameSession,
    setOutcomeGames,
    setPlayGamesLoader,
    setProvidersOutcome,
    setSelectedProviderName
} from "./playGamesSlice";
import axios, { AxiosError } from "axios";
import Config, { getApiHost } from "../../config";

export const usePlayGames = (): iUsePlayGames => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => state.playGames)
    const apiURL = getApiHost()

    const getProviders = useCallback((onSuccess: () => void = () => {}) => {
        if (apiURL){
            dispatch(setPlayGamesLoader({
                loader: PlayGamesLoader.isProvidersOutcomeLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowProviders')

            axios.post<ShowProvidersResponse>(apiURL, data)
                .then(response => {
                    const { success, error, providers } = response.data
                    if (success && providers !== undefined) {
                        dispatch(setProvidersOutcome(providers))
                        onSuccess()
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setProvidersOutcome([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setProvidersOutcome([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setPlayGamesLoader({
                        loader: PlayGamesLoader.isProvidersOutcomeLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError])

    const getGames = useCallback((providerName?: string) => {
        if (providerName !== state.selectedProvider) {
            dispatch(setSelectedProviderName(providerName ?? null))
        }
        if (apiURL) {
            dispatch(setPlayGamesLoader({
                loader: PlayGamesLoader.isOutcomeGameLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetGames')
            if (providerName) {
                data.append('provider', providerName)
            }
            if (token) {
                data.append('token', token)
            }

            axios.post<GetGamesResponse>(apiURL, data)
                .then(response => {
                    const { success, error, games } = response.data
                    if (success && games) dispatch(setOutcomeGames(games))
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setPlayGamesLoader({
                        loader: PlayGamesLoader.isOutcomeGameLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.selectedProvider, token])

    const startGameSession = useCallback((gameId: number) => {
        if (token && apiURL) {
            dispatch(setPlayGamesLoader({
                loader: PlayGamesLoader.isGameSessionLoading,
                isLoading: true
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'StartSession')
            data.append('token', token)
            data.append('game', gameId.toString())

            axios.post<StartGameSessionResponse>(apiURL, data)
                .then(response => {
                    const { success, error, SessionId, SessionUrl } = response.data
                    if (success) {
                        if (SessionId) {
                            dispatch(setGameSession({
                                SessionId: SessionId ?? null,
                                SessionUrl: SessionUrl ?? null
                            }))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPlayGamesLoader({
                        loader: PlayGamesLoader.isGameSessionLoading,
                        isLoading: false
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token])

    return { ...state, getProviders, getGames, startGameSession }
}