import { FC } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { SideMenuSectionMenu } from "./SideMenuSectionMenu";
import { SideMenuSectionMenuItem } from "./SideMenuSectionMenuItem";
import jetx from '../../../../assets/gnms-mobile-banners/jetx.png'
import aviator from '../../../../assets/gnms-mobile-banners/aviator.png'
import spaceman from '../../../../assets/gnms-mobile-banners/spaceman.png'
import rocketman from '../../../../assets/gnms-mobile-banners/rocketman.png'

import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';


export const SideMenu: FC = () => {
    const screenWidthSmallerThan350 = useMediaQuery('( max-width: 350px )')

    return <Box
        px={'8px'}
        display={'flex'}
        flexDirection={'column'}
    >
        <SideMenuSectionMenu title={'Deporte'}>
            <SideMenuSectionMenuItem title={'Deporte'} icon={SportsSoccerOutlinedIcon} link={'/dashboard'}/>
        </SideMenuSectionMenu>
        <SideMenuSectionMenu title={'Juegos'}>
            <SideMenuSectionMenuItem title={'Casino'} icon={CasinoOutlinedIcon} link={'/slots'}/>
            <SideMenuSectionMenuItem title={'Apuestes En Vivo'} icon={OndemandVideoOutlinedIcon} link={'/slots'}/>
            <SideMenuSectionMenuItem title={'Casino En Vivo'} icon={StyleOutlinedIcon} link={'/slots'}/>
        </SideMenuSectionMenu>
        <SideMenuSectionMenu title={'Top Juegos de Crash'}>
            <Box display={'flex'} gap={'8px'}>
                <SideMenuSectionMenuItem title={'Jetx'} imageIconLink={jetx} link={'/slots'} adaptive={screenWidthSmallerThan350} horizontalMode/>
                <SideMenuSectionMenuItem title={'Aviator'} imageIconLink={aviator} link={'/slots'} adaptive={screenWidthSmallerThan350} horizontalMode/>
                <SideMenuSectionMenuItem title={'Spaceman'} imageIconLink={spaceman} link={'/slots'} adaptive={screenWidthSmallerThan350} horizontalMode/>
                <SideMenuSectionMenuItem title={'Rocketman'} imageIconLink={rocketman} link={'/slots'} adaptive={screenWidthSmallerThan350} horizontalMode/>
            </Box>
        </SideMenuSectionMenu>
    </Box>
}