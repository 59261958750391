import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const ruLang: LangPack = {
    lang: Lang.RU,
    langName: "Russian",
    langShort: "ru",
    pack: {
        common: {
            noBTN: "Нет", yesBTN: "Да", errorCodeTitle: "Ошибка", pageSizeSwitcherLabel: "Строк на страницу"
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Вы уверены, что хотите выйти из аккаунта?",
            logOut: "Выйти из аккаунта",
            depositMoney: "Пополнение счета",
            gamesHistory: "История игр",
            transactions: "Транзакции",
            paymentAccounts: "Платежные аккаунты",
            userInfo: "Информация пользователя",
            withDrawMoney: "Вывод денег"
        },
        mobileMenu: {
            profile: 'Профиль',
            topUpBalance: 'Пополнить баланс',
            logout: 'Выйти'
        },
        dashboard: {
            title: 'Панель управления',
            youMustBeLoggedMessage: 'Вам нужно войти, чтобы видеть данную секцию!'
        },
        internalErrorMessages: {
            networkError: "Произошла сетевая ошибка при выполнении запроса.",
            error502: "Произошла ошибка сервера 502 при выполнении запроса."
        },
        errorMessages: {
            2: "Нет записей",
            3: "Адрес электронной почты пользователя уже существует",
            4: "Номер телефона пользователя уже существует",
            5: "Пользователь уже вошел в систему",
            6: "Неверный пароль",
            7: "Пустой результат игр",
            8: "ID результата не совпадает с ID запроса",
            9: "Ошибка запроса результата",
            10: "ID сеанса отсутствует в ответе",
            11: "Пользователь уже существует",
            12: "Токен недействителен. Войдите повторно.",
            13: "У вас недостаточно прав для выполнения этого действия",
            14: "Отсутствует идентификатор дочернего пользователя для этого действия",
            15: "Отсутствует сумма денег",
            16: "Действие не существует",
            17: "Отсутствует токен",
            18: "Недостаточно средств",
            19: "Должны быть родителем этого пользователя для выполнения этого действия",
            20: "Пользователь не существует",
            21: "Неверный тип значения для этого поля",
            22: "Отсутствует пароль",
            23: "Отсутствует имя пользователя",
            24: "Отсутствует идентификатор игры",
            25: "Отсутствует новый пароль",
            26: "Не удалось установить токен",
            27: "Не удалось удалить токен",
            28: "Отсутствует идентификатор пользователя",
            29: "Неверный формат даты и времени",
            30: "Не удалось обновить блокировку пользователя",
            31: "Этот пользователь должен быть в вашем дереве для выполнения этого действия",
            32: "Этот пользователь заблокирован и не может выполнять никаких действий",
            33: "Отсутствует валюта",
            34: "Отсутствует максимальное значение",
            35: "Тип лимита должен быть единичным, ежедневным, еженедельным или ежемесячным",
            36: "Отсутствует тип лимита",
            37: "Поставщик не существует",
            38: "Отсутствует сумма условия уровня",
            39: "Отсутствует тип условия уровня",
            40: "Отсутствует заголовок уровня",
            41: "Cashback для этого уровня уже существует",
            42: "Нет cashback для этого уровня",
            43: "Бонус для этого промокода уже существует",
            44: "Бонус не существует",
            45: "Отсутствует промокод",
            46: "Отсутствует время начала",
            47: "Отсутствует сумма условия для депозита",
            48: "Отсутствует ставка",
            49: "Отсутствует срок действия в днях",
            50: "Отсутствует идентификатор",
            51: "Отсутствует тип cashback, должен быть 'ставка' или 'депозит'",
            52: "Отсутствует ставка cashback",
            53: "Эта валюта недоступна",
            54: "Отсутствует параметр блокировки - true или false",
            55: "Группа банка для этой валюты не является допустимой",
            56: "Отсутствует название способа оплаты",
            57: "Отсутствуют обязательные параметры для этого способа оплаты",
            58: "Этот способ оплаты не существует",
            59: "Этот способ оплаты не поддерживает валюту этого пользователя",
            60: "У этого пользователя нет существующего счета для этой системы оплаты",
            61: "Родитель этого пользователя не имеет существующего счета для этой системы оплаты",
            62: "Отсутствует идентификатор трансфера",
            63: "Трансфер с этим идентификатором не существует",
            64: "Этот трансфер запрошен другим родителем",
            65: "У вас все еще есть бонус, который не истек"
        },
        loginForm: {
            usernameFieldLabel: "Никнейм",
            passwordFieldLabel: "Пароль",
            loginBTN: "Логин"
        },
        userInfoForm: {
            emailIsRequiredMess: 'Email обязательный',
            notValidEmail: 'Введите корректный адрес email',
            currentPassFieldLabel: "Текущий пароль",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "Это поле не должно быть пустым",
            firstnameFieldLabel: "Имя",
            lastnameFieldLabel: "Фамилия",
            newPassFieldLabel: "Новый пароль",
            passNotMatchWarningMessage: "Новый пароль и повтор пароля не совпадают",
            phoneFieldLabel: "Телефон",
            retypePassFieldLabel: "Новый пароль еще раз",
            saveInfoBTN: "Сохранить инфо",
            updatePassBTN: "Обновить пароль",
            usernameLabel: "Никнейм"
        },
        transactionsTable: {
            amountTableHeader: "Сумма",
            methodTableHeader: "Метод",
            statusTableHeader: "Статус",
            depositWithdrawTableHeader: "Пополнение/Вывод",
            dateTimeTableHeader: "Дата и время"
        },
        gamesHistory: {
            providerTableHeader: 'Провайдер',
            gameTableHeader: 'Игра',
            betAmountTableHeader: 'Сумма ставки',
            winAmountTableHeader: 'Сумма выигрыша',
            dateTimeTableHeader: 'Дата и время'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Нет доступных способов оплаты",
            paymentMethodsDropdownLabel: "Способы оплаты",
            addBTN: "Добавить",
            yourPaymentAccountsTitle: "Ваши платежные счета",
            addPaymentMethodBTN: "Добавить способ оплаты"
        },
        moneyInput: {
            maxAmountMessage: "Максимальное значение",
            exceedAmountError: "Превышено максимальное значение.",
            moreThanOneDotError: "Вы ввели лишнюю точку.",
            moreThanTwoDecimals: "Вы ввели более двух знаков после запятой."
        },
        withdrawMoney: {
            requestPayoutLabel: "Запрос вывода средств",
            moneySuccessfulRequestedMessage: "Вы успешно запросили %money% для выплаты.",
            requestBtn: "Запросить деньги"
        }
    }
}