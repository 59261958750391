import { FC } from "react";
import { useUser } from "../../features/user/useUser";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import { CurrencyRender } from "../common/CurrencyRender";
import { Box, useMediaQuery } from "@mui/material";
import CardGiftcardTwoToneIcon from '@mui/icons-material/CardGiftcardTwoTone';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';

export const BalanceDisplay: FC = () => {
    const { user } = useUser()
    const currencyRender = user?.user_currency ? <CurrencyRender currency={user.user_currency}/> : <></>
    const extraSmallWidthScreen = useMediaQuery('(max-width:388px)')

    return user ? <>
        <Box
            display={'flex'}
            alignItems={'flex-start'}
            color={'#0096ff'}>
            <CachedTwoToneIcon/>&nbsp;
            {user.user_freespins}
        </Box>

        <Box
            display={'flex'}
            alignItems={'flex-start'}
            color={'#64c200'}>
            {!extraSmallWidthScreen && <><CardGiftcardTwoToneIcon/>&nbsp;</>}
            {convertCentsToCurrencyString(user.user_bonus_balance)}{currencyRender}
        </Box>
        <Box display={'flex'} alignItems={'flex-start'}>
            {convertCentsToCurrencyString(user.user_balance)}{currencyRender}
        </Box>
    </> : <></>
}