import { FC } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

interface DesktopTopBarMenuItemProps {
    isActive?: boolean
    title: string
    link: string
}

export const DesktopTopBarMenuItem: FC<DesktopTopBarMenuItemProps> = ({isActive = false, link, title}) => {
    return <Box
        maxWidth={'100px'}
        sx={{backgroundColor: isActive ? 'var(--dark-3)' : 'initial'}}
        height={'100%'}
        borderBottom={`solid 2px ${isActive ? 'var(--secondary-color)' : 'transparent'}`}
    >
        <Link to={link}>
            <Box px={'18px'} display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Typography lineHeight={'13px'} fontSize={'13px'} textTransform={'uppercase'} textAlign={'center'}>{title}</Typography>
            </Box>
        </Link>
    </Box>
}