import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { UserSlice } from "./features/user/userSlice";
import { GamesSlice } from "./features/games/gamesSlice";
import { ErrorSlice } from "./features/ApiErrors/errorSlice";
import { GameHistorySlice } from "./features/gameHistory/gameHistorySlice";
import { TransactionsSlice } from "./features/transactions/transactionsSlice";
import { LanguageSlice } from "./features/localisation/LanguageSlice";
import { PaymentSystemsSlice } from "./features/paymentSystems/paymentSystemsSlice";
import { PlayGamesSlice } from "./features/play_games/playGamesSlice";

const store = configureStore({
    reducer: {
        lang: LanguageSlice.reducer,
        user: UserSlice.reducer,
        games: GamesSlice.reducer,
        gamesHistory: GameHistorySlice.reducer,
        transactions: TransactionsSlice.reducer,
        paymentSystems: PaymentSystemsSlice.reducer,
        errors: ErrorSlice.reducer,
        playGames: PlayGamesSlice.reducer
    }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector