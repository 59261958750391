import { FC } from "react";
import { Box } from "@mui/material";
import { DesktopTopBarMenuItem } from "./DesktopTopBarMenuItem";

export const DesktopTopBarMenu: FC = () => {
    const isActive = (path: string) => {
        return window.location.pathname === path
    }

    return <Box display={'flex'} columnGap={'10px'} height={'100%'}>
            <DesktopTopBarMenuItem title={'Inicio'} link={"/"} isActive={isActive("/")}/>
            <DesktopTopBarMenuItem title={'Casino'} link={"/casino"} isActive={isActive("/casino")}/>
            <DesktopTopBarMenuItem title={'Casino en vivo'} link={"/live-casino"} isActive={isActive("/live-casino")}/>
            <DesktopTopBarMenuItem title={'Juegos Virtuales'} link={"/golden-race"} isActive={isActive("/golden-race")}/>
        </Box>
}