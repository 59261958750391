import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { setGamePageSize } from "../../../features/gameHistory/gameHistorySlice";
import { useTransactions } from "../../../features/transactions/useTransactions";
import s from "./Transactions.module.css";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { PageSizeSwitcher } from "../../../components/common/pageSizeSwitcher/PageSizeSwitcher";
import { getNumberParam } from "../../../features/common_funcs";
import { TransactionsTable } from "./TransactionsTable";
import { useMediaQuery } from "@mui/material";
import { TransactionListCardStyle } from "../../../components/transaction/TransactionListCardStyle";

export const Transactions: FC = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const tabletAndLarge = useMediaQuery('(min-width:700px)')
    const { pageSize, currentPage, getTransactions, totalPages, transactions, clearTransactions } = useTransactions()

    useEffect(() => {
        return () => {
            clearTransactions()
        }
    }, [ clearTransactions ])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page: number|null = getNumberParam(searchParams.get('page'))
        const itemsOnPage: number|null = getNumberParam(searchParams.get('pageSize'))
        if ((itemsOnPage && itemsOnPage !== pageSize) && (page && page !== currentPage)) {
            getTransactions(page, itemsOnPage)
        } else if ((itemsOnPage && itemsOnPage !== pageSize) && transactions.length === 0) {
            getTransactions(1, itemsOnPage)
        } else if (itemsOnPage && itemsOnPage !== pageSize) {
            dispatch(setGamePageSize(itemsOnPage))
            getTransactions(1, itemsOnPage)
        } else if (page && page !== currentPage) {
            getTransactions(page)
        } else if (transactions.length === 0) {
            getTransactions(1)
        } else if (!Boolean(itemsOnPage) && !Boolean(page) && (pageSize !== 30 || currentPage !== 1) && transactions.length !== 0) {
            getTransactions(1, 30)
        }
    }, [currentPage, transactions, dispatch, getTransactions, location.search, pageSize])

    return <div className={s.container}>
        <div className={s.tableContainer}>
            {tabletAndLarge ? <TransactionsTable/> : <TransactionListCardStyle/>}
        </div>
        <div className={s.bottomBar}>
            <Paginator totalPages={totalPages} currentPage={currentPage}/>
            <span className={s.flexGrow}/>
            <PageSizeSwitcher pageSize={pageSize}/>
        </div>
    </div>
}