import { Box } from "@mui/material";
import jetx from "../../../assets/gnms-banners/jetx.png";
import aviator from "../../../assets/gnms-banners/aviator.png";
import spaceman from "../../../assets/gnms-banners/spaceman.png";
import rocketman from "../../../assets/gnms-banners/rocketman.png";
import { FC } from "react";

export const BannersSection: FC = () => {
    return <Box px={'10px'} display={'flex'} columnGap={'10px'}>
        <Box flex={1}>
            <img width={'100%'} src={jetx}/>
        </Box>
        <Box flex={1}>
            <img width={'100%'} src={aviator}/>
        </Box>
        <Box flex={1}>
            <img width={'100%'} src={spaceman}/>
        </Box>
        <Box flex={1}>
            <img width={'100%'} src={rocketman}/>
        </Box>
    </Box>
}