import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ElementType = React.ElementType;

interface SideMenuSectionMenuItemProps {
    title: string
    icon?: ElementType
    imageIconLink?: string
    link: string
    isActive?: boolean
    horizontalMode?: boolean
    adaptive?: boolean
}

export const SideMenuSectionMenuItem: FC<SideMenuSectionMenuItemProps> = ({ title, icon, imageIconLink, link, isActive = false, horizontalMode = false, adaptive = false }) => {
    return <Box flex={1}><Link to={link}>
        {horizontalMode ?
            <Box
                display={'flex'}
                px={'6px'}
                py={'10px'}
                flexDirection={'column'}
                borderRadius={'8px'}
                alignItems={'center'}
                rowGap={'8px'}
                height={'100%'}
                sx={{
                    backgroundColor: isActive ? 'var(--primary-color)' : 'var(--dark-2)'
                }}
            >
                {icon && React.createElement(icon)}
                {!Boolean(icon) && imageIconLink && <img height={adaptive ? '30vw' : '25px'} src={imageIconLink} alt={title}/>}
                <Typography fontSize={adaptive ? '2.5vw' : '9px'} textAlign={'center'}>
                    {title}
                </Typography>
            </Box>
            :
            <Box
                display={'flex'}
                px={'16px'}
                py={'10px'}
                borderRadius={'8px'}
                alignItems={'center'}
                columnGap={'8px'}
                sx={{
                    backgroundColor: isActive ? 'var(--primary-color)' : 'var(--dark-2)'
                }}
            >
                {icon && React.createElement(icon)}
                {!Boolean(icon) && imageIconLink && <img height={'20px'} src={imageIconLink} alt={title}/>}
                <Typography fontSize={'13px'}>
                    {title}
                </Typography>
            </Box>}
    </Link></Box>
}