import { FC, useCallback, useEffect, useRef, useState } from "react";
import slideOne from '../../../../assets/gnms-slider/01.png'
import slideTwo from '../../../../assets/gnms-slider/02.png'
import slideThree from '../../../../assets/gnms-slider/03.png'
import slideFour from '../../../../assets/gnms-slider/04.png'
import slideFive from '../../../../assets/gnms-slider/05.png'
import Flickity, { FlickityOptions } from "react-flickity-component";
import "./flickity-style-overrides.css";
import { Box } from "@mui/material";

const flickityOptions: FlickityOptions = {
    autoPlay: 4500,
    wrapAround: true
}

export const Slider: FC = () => {
    const sliderRef = useRef<HTMLDivElement>(null)
    const [sliderWidth, setSliderWidth] = useState<number>(0)

    const updateWidth = useCallback(() => {
        if (sliderRef.current) {
            const newWidth = sliderRef.current.offsetWidth
            if (sliderWidth !== newWidth) {
                setSliderWidth(newWidth)
            }
        }
    }, [sliderWidth])

    useEffect(() => {
        updateWidth()
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [updateWidth]);

    return <Box
        ref={sliderRef}
        width={'95.8vw'}
        mx={'auto'}
        mb={'20px'}
    >
        {sliderWidth > 0 && <Flickity options={flickityOptions}>
            <img width={`${sliderWidth}px`} src={slideOne}/>
            <img width={`${sliderWidth}px`} src={slideTwo}/>
            <img width={`${sliderWidth}px`} src={slideThree}/>
            <img width={`${sliderWidth}px`} src={slideFour}/>
            <img width={`${sliderWidth}px`} src={slideFive}/>
        </Flickity>}
    </Box>
}