import { useLocation } from "react-router-dom";
import { usePlayGames } from "./usePlayGames";
import { useEffect } from "react";

export const useProviderWatcher = () => {
    const location = useLocation()
    const { getGames, selectedProvider, isOutcomeGameLoading } = usePlayGames()
    
    useEffect(() => {
        if (!isOutcomeGameLoading) {
            const searchParams = new URLSearchParams(location.search)
            const qProviderName = searchParams.get('provider_name')
            
            const qNameHasChanges = qProviderName !== null && qProviderName !== selectedProvider
            
            if (qNameHasChanges) {
                console.group('Provider has changes')
                console.log('qNameHasChanges', qNameHasChanges)
                console.groupEnd()
                
                getGames(qProviderName)
            }
        }
    }, [getGames, isOutcomeGameLoading, location.search, selectedProvider])
}