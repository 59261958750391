import { FC, ReactNode } from "react";
import s from './InfoWithLabel.module.css'
import clsx from "clsx";

interface InfoWithLabelProps {
    children?: ReactNode|string
    label: string
}

export const InfoWithLabel: FC<InfoWithLabelProps> = ({children, label}) => {
    return <div className={s.container}>
        <div className={clsx(s.title, !Boolean(children) && s.gray)}>
            {children}
        </div>
        <div className={s.label}>
            {label}
        </div>
    </div>
}