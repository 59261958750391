import { FC } from "react";
import { PageLayout } from "../../components/ganamos_layout/layout/PageLayout";
import { ContentSection } from "../../components/ganamos_layout/content_section/ContentSection";
import Grid from "@mui/material/Unstable_Grid2";
import { GameCard } from "../../components/ganamos_layout/GameCard";
import { useMediaQuery } from "@mui/material";

import game01 from '../../../assets/temp/gnms-top-games/vs5joker.png'
import game02 from '../../../assets/temp/gnms-top-games/buffalo.jpg'
import game03 from '../../../assets/temp/gnms-top-games/vs10trail.png'
import game04 from '../../../assets/temp/gnms-top-games/vs10txbigbass.png'
import game05 from '../../../assets/temp/gnms-top-games/1_300x300.png'
import game06 from '../../../assets/temp/gnms-top-games/vs15diamond.png'
import game07 from '../../../assets/temp/gnms-top-games/vs25pyramid.png'
import game08 from '../../../assets/temp/gnms-top-games/vs20gravity.png'

import { BannersSection } from "./BannersSection";


export const HomePage: FC = () => {
    const mobileScreens = useMediaQuery('(min-width: 768px)')

    return <PageLayout>

        {mobileScreens && <BannersSection/>}

        <ContentSection sectionTitle={'Mejores Tragamonedas'} linkToFullSection={'/casino'}>
            <Grid container rowSpacing={'15px'} columnSpacing={'10px'} columns={8}>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game01} title={'Joker\'s Jewels'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game02} title={'Buffalo'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game03} title={'Mustang Trail'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game04} title={'Big Bass Splash'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game05} title={'Madame Luck'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game06} title={'Diamond Strike'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game07} title={'Pyramid King'} square/>
                </Grid>
                <Grid xs={2} md={1}>
                    <GameCard imageURL={game08} title={'Gravity Bonanza'} square/>
                </Grid>
            </Grid>
        </ContentSection>
        <ContentSection sectionTitle={'Pragmatic Lives'} linkToFullSection={'/live-casino'}/>
    </PageLayout>
}