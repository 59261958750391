import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    // typography: {
    //     fontFamily: 'Comfortaa, sans-serif'
    // },
    palette: {
        mode: 'dark',
        primary: {
            light: '#9907da',
            main: '#8B00C9',
            dark: '#7c03b2',
            contrastText: '#fff'
        },
        secondary: {
            light: '#fcc12b',
            main: '#FFBC0F',
            dark: '#e78e08',
            contrastText: '#030303'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                },
            },
        },
    },
});