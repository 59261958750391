import { FC } from "react";
import { useMediaQuery } from "@mui/material";
import { TopBarMobile } from "./TopBarMobile";
import { TopBarDesktop } from "./TopBarDesktop";

interface TopBarProps {
    onHeightChanged?: (height: number) => void
}

export const TopBar: FC<TopBarProps> = ({onHeightChanged}) => {
    const mobileScreens = useMediaQuery('only screen and (max-width: 768px)')

    return <>
        {mobileScreens ? <TopBarMobile onHeightChanged={onHeightChanged}/> : <TopBarDesktop/>}
    </>
}