import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Config from "../../../config";

interface ContentSectionProps {
    sectionTitle: string
    linkToFullSection: string
    children?: ReactNode
    id?: string
    imageIconUrl?: string
}

export const ContentSection: FC<ContentSectionProps> = ({ sectionTitle, linkToFullSection, children, id, imageIconUrl }) => {
    return <Box
        id={id}
        px={'10px'}
    >
        {/* ------- Header ------- */}
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Box display={'flex'} columnGap={'20px'} alignItems={'center'}>
                {imageIconUrl !== undefined && <img alt={sectionTitle} src={`${Config.hostDomain}${imageIconUrl}`} height={'25px'}/>}

                <Typography fontSize={'15px'} fontWeight={'600'} py={'16px'}>
                    {sectionTitle}
                </Typography>
            </Box>
            <Link to={linkToFullSection}>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    columnGap={'10px'}
                    sx={{ opacity: '0.4' }}
                >
                    <Box display={'flex'} width={'2px'} height={'100%'} sx={{ backgroundColor: 'white' }}
                         mx={'8px'}>&nbsp;</Box>
                    <Typography>
                        Todos
                    </Typography>
                    <ArrowForwardIosIcon fontSize={'small'}/>
                </Box>
            </Link>
        </Box>

        <Box>
            {children}
        </Box>
    </Box>
}