import { FC } from "react";
import { useGamesHistory } from "../../features/gameHistory/useGamesHistory";
import { useUser } from "../../features/user/useUser";
import { Box } from "@mui/material";
import { GamesHistoryItemCard } from "./GamesHistoryItemCard";

export const GamesHistoryListCardStyle: FC = () => {
    const { gameHistory } = useGamesHistory()
    const { user } = useUser()

    return <Box display={'flex'} flexDirection={'column'} height={'100%'} overflow={'auto'} width={'100%'} rowGap={'10px'}>
        {gameHistory && gameHistory.map(game => <GamesHistoryItemCard currency={user?.user_currency} key={game.uuid} gameHistoryItem={game}/>)}
    </Box>
}