import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const enLang: LangPack = {
    lang: Lang.EN,
    langName: "English",
    langShort: "en",
    pack: {
        common: { noBTN: "No", yesBTN: "Yes", errorCodeTitle: "Error code", pageSizeSwitcherLabel: "Rows on page" },
        dashboardMenu: {
            logOutConfirmationMessage: "Are you sure you want to log out?",
            logOut: "Log Out",
            depositMoney: "Deposit Money",
            gamesHistory: "Games History",
            transactions: "Transactions",
            paymentAccounts: "Payment accounts",
            userInfo: "User Info",
            withDrawMoney: "Withdraw Money"
        },
        mobileMenu: {
            profile: 'Profile',
            topUpBalance: 'Top Up balance',
            logout: 'Log Out'
        },
        dashboard: {
            title: 'Dashboard',
            youMustBeLoggedMessage: 'You must be logged in to view this section!'
        },
        internalErrorMessages: {
            networkError: "A network error occurred while executing the request.",
            error502: "A 502 server error occurred while executing the request."
        },
        errorMessages: {
            2: "No entries",
            3: "User email address already existing",
            4: "User phone number already existing",
            5: "User already logged in",
            6: "Wrong password",
            7: "Empty games result",
            8: "Result ID does not match the request ID",
            9: "Outcome request error",
            10: "Outcome no session ID in the response",
            11: "User already existing",
            12: "Your token is not valid. Login again.",
            13: "You have not enough rights to perform this action",
            14: "User ID for this action missing",
            15: "Money amount missing",
            16: "Action does not exist",
            17: "Token missing",
            18: "Not enough money",
            19: "You must be the parent of this user to perform this action",
            20: "User does not exist",
            21: "The value type for this field is wrong",
            22: "Password missing",
            23: "User name missing",
            24: "Game ID missing",
            25: "New password missing",
            26: "Token could not be set up",
            27: "Token could not be deleted",
            28: "User ID missing",
            29: "Wrong datetime format",
            30: "Could not update user ban",
            31: "This user must be in your tree to perform this action",
            32: "This user is banned and cannot perform any actions",
            33: "Currency missing",
            34: "Maximum value missing",
            35: "Limit type must be once, daily, weekly, or monthly",
            36: "Limit type missing",
            37: "Provider does not exist",
            38: "Tier condition amount missing",
            39: "Tier condition type missing",
            40: "Tier title missing",
            41: "Cashback for this tier is already existing",
            42: "Cashback for this tier not existing",
            43: "Bonus for this promocode is already existing",
            44: "Bonus does not exist",
            45: "Promocode missing",
            46: "Start time missing",
            47: "Deposit amount condition missing",
            48: "Wager missing",
            49: "Amount validity days missing",
            50: "ID missing",
            51: "Cashback type missing, must be 'wager' or 'deposit'",
            52: "Cashback rate missing",
            53: "This currency is not available",
            54: "Ban parameter missing - true or false",
            55: "The bank group for this currency is not valid",
            56: "Payment method name missing",
            57: "Mandatory parameters for this payment method missing",
            58: "This payment method does not exist",
            59: "This payment method does not support the currency of this user",
            60: "This user does not have an existing account for this payment system",
            61: "The parent of this user does not have an existing account for this payment system",
            62: "Transfer ID missing",
            63: "Transfer with this ID does not exist",
            64: "This transfer is requested by a different parent",
            65: "You still have a bonus which is not expired"
        },
        loginForm: {
            usernameFieldLabel: "Username",
            passwordFieldLabel: "Password",
            loginBTN: "Login"
        },
        userInfoForm: {
            emailIsRequiredMess: 'Email is required',
            notValidEmail: 'Enter a valid email address',
            currentPassFieldLabel: "Current password",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "This field must not be empty",
            firstnameFieldLabel: "Firstname",
            lastnameFieldLabel: "Lastname",
            newPassFieldLabel: "New password",
            passNotMatchWarningMessage: "New password and retype password do not match",
            phoneFieldLabel: "Phone",
            retypePassFieldLabel: "Retype new password",
            saveInfoBTN: "Save info",
            updatePassBTN: "Update password",
            usernameLabel: "Username"
        },
        transactionsTable: {
            amountTableHeader: "Amount",
            methodTableHeader: "Method",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Deposit/Withdraw",
            dateTimeTableHeader: "Date/Time"
        },
        gamesHistory: {
            providerTableHeader: 'Provider',
            gameTableHeader: 'Game',
            betAmountTableHeader: 'Bet amount',
            winAmountTableHeader: 'Win amount',
            dateTimeTableHeader: 'Date/Time'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "There are no more payment methods available",
            paymentMethodsDropdownLabel: "Payment methods",
            addBTN: "Add",
            yourPaymentAccountsTitle: "Your payment accounts",
            addPaymentMethodBTN: "Add payment method"
        },
        moneyInput: {
            maxAmountMessage: "Maximum value",
            exceedAmountError: "Maximum value exceeded.",
            moreThanOneDotError: "You made an extra point.",
            moreThanTwoDecimals: "You entered more than two decimal places."
        },
        withdrawMoney: {
            requestPayoutLabel: "Withdraw from user",
            moneySuccessfulRequestedMessage: "You have requested %money% for payout.",
            requestBtn: "Request money"
        }
    }
}