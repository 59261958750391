import { User } from "./user";

export const chooseName = (user: User | null) => {
    let name = user?.user_name ?? 'Noname'
    if (user) {
        if ((user.user_firstname && user.user_firstname !== '') || (user.user_lastname && user.user_lastname !== '')) {
            const firstLastName = []
            if (user.user_firstname) firstLastName.push(user.user_firstname)
            if (user.user_lastname) firstLastName.push(user.user_lastname)
            if (firstLastName.length > 0) {
                name = firstLastName.join(' ')
            }
        }
    }
    return name
}