import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { useUser } from "./app/features/user/useUser";
import { useLanguage } from "./app/features/localisation/useLanguage";

import { UserDashboard } from "./app/pages/DashboardV2/UserDashboard";
import { usePlayGames } from "./app/features/play_games/usePlayGames";
import { HomePage } from './app/pages/gnms_home_page/HomePage';
import { CasinoPage } from "./app/pages/gnms_casino_page/CasinoPage";

export const App = () => {
    const { switchLanguageFromStore } = useLanguage()
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const { gameSession: { SessionUrl } } = usePlayGames()
    const [ timeToRefresh, setTimeToRefresh ] = useState<number>(5000)
    const [ intervalId, setIntervalId ] = useState<NodeJS.Timeout | null>(null);
    const { refreshUserBalance, refreshUserInfo } = useUser()

    const prevSessionUrl = useRef<string | null | undefined>();
    const prevTimeToRefresh = useRef<number|undefined>();

    useEffect(() => {
        if (initLoad) {
            setInitLoad(false)
            refreshUserInfo()
            switchLanguageFromStore()

            const envMode = process.env.REACT_APP_ENVIRONMENT === "staging" ? "(STA) " : "";
            document.title = envMode + "Ganamos";
        }
    }, [initLoad, refreshUserInfo, switchLanguageFromStore])

    useEffect(() => {
        if (SessionUrl !== prevSessionUrl.current) {
            if (SessionUrl !== null) {
                setTimeToRefresh(5000)
            } else {
                setTimeToRefresh(60000)
            }
        }

        prevSessionUrl.current = SessionUrl;
    }, [SessionUrl])

    useEffect(() => {
        if (timeToRefresh !== prevTimeToRefresh.current) {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
            const newIntervalId = setInterval(() => {
                refreshUserBalance()
            }, timeToRefresh);
            setIntervalId(newIntervalId);
        }

        prevTimeToRefresh.current = timeToRefresh;
    }, [timeToRefresh, intervalId, refreshUserBalance]);

    return <>
        <Routes>
            {/*<Route path={"*"} element={<HomePage/>}>*/}
            {/*    <Route path={'games'} element={<HomePage/>}/>*/}
            {/*</Route>*/}

            <Route path={'/'} element={<HomePage/>}/>
            <Route path={'/casino'} element={<CasinoPage/>}/>
            <Route path={"/dashboard"} element={<UserDashboard/>}/>
        </Routes>
    </>
}

export default App;
