import React, { FC, useEffect, useState } from "react";
import { useUser } from "../../features/user/useUser";
import { UserInfoEditor } from "./user_info/UserInfoEditor";
import { Transactions } from "./transactions/Transactions";
import { GameHistory } from "./gameHistory/GameHistory";
import { PaymentAccounts } from "./payment_accounts/PaymentAccounts";
import { DepositMoney } from "./deposite_money/DepositMoney";
import { WithdrawMoney } from "./withdraw_money/WithdrawMoney";
import { useLocation } from "react-router-dom";

export const DashboardContent: FC = () => {
    const { token, user } = useUser()
    const location = useLocation();
    const [ tab, setTab ] = useState<string>('userInfo')

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const selectedTab = searchParams.get('tab');
        if (selectedTab) {
            setTab(selectedTab)
        }
    }, [ location.search ]);

    return user && token ? <>
        {(() => {
            switch (tab) {
                case 'userInfo':
                    return <UserInfoEditor/>;
                case 'transactions':
                    return <Transactions/>;
                case 'gamesHistory':
                    return <GameHistory/>;
                case 'paymentAccounts':
                    return <PaymentAccounts/>;
                case 'depositMoney':
                    return <DepositMoney/>;
                case 'withDrawMoney':
                    return <WithdrawMoney/>
                default:
                    return null;
            }
        })()}
    </> : <></>
}