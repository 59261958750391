import { GameHistoryState, GameHistoryModels } from "./GameHistoryModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertUnixToCompactLocaleDate } from "../common_funcs";

const initialState: GameHistoryState = {
    gameHistory: null,
    pageSize: 30,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    isGameHistoryLoading: false
}

export const GameHistorySlice = createSlice({
    name: 'games_history',
    initialState,
    reducers: {
        setGameHistory: (state, action: PayloadAction<GameHistoryModels[]>) => {
            state.gameHistory = action.payload.map((game) => ({
                ...game,
                time: convertUnixToCompactLocaleDate(game.time_unix),
            }))
        },
        clearGameHistory: (state) => {
            state.gameHistory = null
        },
        setCurrentGameHistoryPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setTotalRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setGamePageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setIsGameHistoryLoading: (state, action: PayloadAction<boolean>) => {
            state.isGameHistoryLoading = action.payload
        }
    }
})

export default GameHistorySlice.reducer

export const {
    setGameHistory,
    setCurrentGameHistoryPage,
    setGamePageSize,
    setTotalRecords,
    setIsGameHistoryLoading,
    clearGameHistory
} = GameHistorySlice.actions