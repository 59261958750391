import React, { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, SwipeableDrawer, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import logo from '../../../../assets/ganamos-logo.png'
import { SideMenu } from "../mobile_side_menu/SideMenu";

interface TopBarMobileProps {
    onHeightChanged?: (height: number) => void
}

export const TopBarMobile: FC<TopBarMobileProps> = ({onHeightChanged = () => {}}) => {
    const screenSmallerWidthThanDrawer = useMediaQuery('(max-width: 500px)')

    const [ isDrawerOpen, setDrawerOpen ] = useState(false)

    const [topBarHeight, setTopBarHeight] = useState<number>(0);

    const topBarRef = useRef<HTMLDivElement>(null);

    const updateHeight = useCallback(() => {
        if (topBarRef.current) {
            const newHeight = topBarRef.current.offsetHeight
            if (topBarHeight !== newHeight) {
                setTopBarHeight(newHeight)
                onHeightChanged(newHeight)
            }
        }
    }, [onHeightChanged, topBarHeight])

    const toggleDrawer = (open: boolean) => (event: SyntheticEvent<{}>) => {
        setDrawerOpen(open);
    }

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    return <>
        <Box
            sx={{ backgroundColor: 'var(--dark-bg-color)' }}
            // sx={{ backgroundColor: '#de7904' }}
             position={'fixed'}
             top={0}
             left={0}
             right={0}
             ref={topBarRef}
             // height={'100px'}
             display={'flex'}
             justifyContent={'space-between'}
             alignItems={'center'}
             px={'20px'}
            mb={'100px'}
            zIndex={900}
        >
            {/* Left side of top bar */}
            <Box
                display={'flex'}
                alignItems={'center'}
            >
                <Box
                    // pl={'20px'}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setDrawerOpen(!isDrawerOpen)}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Box>

                <Box mt={'10px'}>
                    <Link to={'/'}>
                        <img src={logo} alt={'logo'} height={'33px'}/>
                    </Link>
                </Box>
            </Box>

            <Box>
                <Button size={'small'} sx={{fontWeight: 800, fontSize: '12px'}} variant={'contained'}>
                    Acceder
                </Button>
            </Box>
        </Box>

        <SwipeableDrawer
            sx={{
                top: `${topBarHeight}px`,
                '.MuiBackdrop-root': {
                    top: `${topBarHeight}px`
                },
                '.MuiPaper-root': {
                    top: `${topBarHeight}px`,
                    backgroundColor: 'var(--dark-bg-color) !important',
                    backgroundImage: 'none'
                }
            }}
            anchor="left"
            open={isDrawerOpen}
            onOpen={toggleDrawer(true)}
            onClose={toggleDrawer(false)}
        >
            <Box
                width={screenSmallerWidthThanDrawer ? '100vw' : '460px'}
                pb={`${topBarHeight + 10}px`}
            >
                <SideMenu/>
            </Box>
        </SwipeableDrawer>
    </>
}