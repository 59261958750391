export const getColorForTransactionApproveStatus = (status: string): string => {
    switch (status) {
        case 'pending':
            return '#fd8700';
        case 'approved':
            return '#2ebb00';
        case 'declined':
        case 'canceled':
            return '#B10040';
        default:
            return 'inherite'; // Цвет по умолчанию, если статус не распознан
    }
}