import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Lang } from "../../../features/localisation/useLanguageInterface";
import { useLanguage } from "../../../features/localisation/useLanguage";

const LanguageSelector: React.FC = () => {
    const { switchLanguage, languagePack: {lang} } = useLanguage()

    const handleLanguageChange = (event: SelectChangeEvent<Lang>) => {
        switchLanguage(event.target.value as Lang);
    };

    return (
        <FormControl size={'small'} variant={'outlined'}>
            <Select
                labelId="language-selector-label"
                id="language-selector"
                value={lang}
                onChange={handleLanguageChange}
            >
                <MenuItem value={Lang.EN}>En</MenuItem>
                {/*<MenuItem value={Lang.RU}>Ru</MenuItem>*/}
                {/*<MenuItem value={Lang.DE}>De</MenuItem>*/}
                {/*<MenuItem value={Lang.FR}>Fr</MenuItem>*/}
                <MenuItem value={Lang.ES}>Es</MenuItem>
                <MenuItem value={Lang.PT}>Pt</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
