import Config from "../config";
import { Lang } from "../features/localisation/useLanguageInterface";

const LANG = Config.localStoragePrefix + 'lang'

class LangStorage {
    static getLang () : Lang | null {
        const s = localStorage.getItem(LANG)
        if (s !== null) return JSON.parse(s) as Lang
        return null
    }

    static setLang (lang: Lang) {
        localStorage.setItem(LANG, JSON.stringify(lang))
    }
}

export default LangStorage