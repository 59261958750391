import { Box, useMediaQuery } from "@mui/material";
import React, { FC, useState } from "react";
import { SideMenuItem } from "./SideMenuItem";
import { menuList } from "./MenuConfig";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { Link, useLocation } from "react-router-dom";
import { LogOut } from "../../../pages/DashboardV2/LogOut";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LanguageSelector from "../../common/lang/LanguageSelector";
import Typography from "@mui/material/Typography";
import { chooseName } from "../../../features/user/userFuncs";
import { useUser } from "../../../features/user/useUser";

interface SideMenuProps {
    close?: () => void
    inDrawer?: boolean
}

export const SideMenu: FC<SideMenuProps> = ({close = () => {}, inDrawer = false}) => {
    const { languagePack: {pack: {dashboardMenu: lang}} } = useLanguage()
    const { user, token } = useUser()
    const userIsLogged = Boolean(user) && Boolean(token)
    const tabletAndLargeScreens = useMediaQuery('(min-width:700px)')

    const menuNames = JSON.parse(JSON.stringify(lang))
    const [ showLogout, setShowLogout ] = useState<boolean>(false)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const tab = searchParams.get('tab')

    const isActiveTab = (checkingTab: string): boolean => {
        return checkingTab === tab
    }

    return <Box
        height={inDrawer ? 'calc(100% - 50px)' : '100%'}
        width={'300px'}
        display={'flex'}
        flexDirection={'column'}
        sx={{
            backgroundImage: 'linear-gradient(-45deg, #171614, #272c2f)',
            overflowY: 'auto'
        }}
    >
        {!tabletAndLargeScreens && userIsLogged && <Box display={'flex'} padding={'8px 16px'} alignItems={'center'} columnGap={'10px'} minHeight={'90px'}>
            <LanguageSelector/>
            <Box>
                <Typography>
                    {chooseName(user)}
                </Typography>
                <Typography fontSize={'10px'} mt={'-7px'}>
                    {user?.user_name}
                </Typography>
            </Box>

        </Box>}

        {userIsLogged && menuList.map((item, index) => <Link to={'/dashboard?tab=' + item.tabName} style={{textDecoration: 'none'}}>
            <SideMenuItem
                onClick={close}
                isFirst={index === 0}
                isLast={menuList.length - 1 === index}
                key={`side-menu-item-${item.tabName}`}
                icon={item.icon}
                title={menuNames[item.tabName]}
                iconColor={item.iconColor}
                isActive={isActiveTab(item.tabName)}
            />
        </Link>)}


        <Box flexGrow={1}/>
        {userIsLogged && <SideMenuItem
            isFirst
            isLast
            title={lang.logOut}
            icon={ExitToAppIcon}
            iconColor={'#14dd99'}
            onClick={() => setShowLogout(true)}
        />}

        {showLogout && <LogOut close={() => {setShowLogout(false)}}/>}
    </Box>
}

