import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { Link } from "react-router-dom";

interface BottomMobileMenuItemProps {
    title: string
    icon: React.ElementType
    link: string
    isActive?: boolean
}

export const BottomMobileMenuItem: FC<BottomMobileMenuItemProps> = ({ title, icon, link, isActive = false }) => {
    return <Box
        sx={{
            backgroundColor: isActive ? 'var(--primary-color)' : 'initial'
        }}
        flex={1}
    >
        <Link to={link}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'start'}
                py={'4px'}
            >
                {React.createElement(icon)}
                <Typography fontSize={'9px'} textAlign={'center'}>{title}</Typography>
            </Box>
        </Link>
    </Box>
}