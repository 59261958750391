import { LangPack } from "./langModel";
import { enLang } from "./packs/enLang";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageState {
    languagePack: LangPack
}

const initialState: LanguageState = {
    languagePack: enLang
}

export const LanguageSlice = createSlice({
    initialState,
    name: 'language',
    reducers: {
        changeLang: (state, action: PayloadAction<LangPack>) => {
            state.languagePack = action.payload
        }
    }
})

export default LanguageSlice.reducer

export const { changeLang } = LanguageSlice.actions