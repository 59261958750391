import { FC, useEffect } from "react";
import { useGamesHistory } from "../../../features/gameHistory/useGamesHistory";
import { GameHistoryTable } from "./GameHistoryTable";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { PageSizeSwitcher } from "../../../components/common/pageSizeSwitcher/PageSizeSwitcher";
import { DashboardContentBox } from "../../../components/dashboard/DashboardContentBox";
import { Box, useMediaQuery } from "@mui/material";
import { GamesHistoryListCardStyle } from "../../../components/games_history/GamesHistoryListCardStyle";
import { useGamesHistoryWatcher } from "../../../features/gameHistory/useGamesHistoryWatcher";


export const GameHistory: FC = () => {
    const tabletAndLarge = useMediaQuery('(min-width:700px)')
    const { pageSize, currentPage, totalPages, clearGameHistory } = useGamesHistory()

    useGamesHistoryWatcher()

    useEffect(() => {
        return () => {
            clearGameHistory()
        }
    }, [ clearGameHistory ])

    return <DashboardContentBox >
        <Box width={'100%'} height={'100%'} display={'flex'}>
            {tabletAndLarge ? <GameHistoryTable/> : <GamesHistoryListCardStyle/>}
        </Box>

        <Box display={'flex'} width={'100%'}>
            <Paginator totalPages={totalPages} currentPage={currentPage}/>
            <Box flexGrow={'1'}/>
            <PageSizeSwitcher pageSize={pageSize}/>
        </Box>
    </DashboardContentBox>
}