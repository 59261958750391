import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';

interface GameCardProps {
    imageURL?: string
    title: string
    onClick?: () => void
    square?: boolean
}

export const GameCard: FC<GameCardProps> = ({imageURL, title, onClick = () => {}, square = false}) => {
    const [ width, setWidth ] = useState<number | undefined>(undefined);
    const [ imageHeight, setImageHeight ] = useState<number | undefined>(undefined)
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateWidth = () => {
            if (componentRef.current) {
                const newWidth = componentRef.current.offsetWidth;
                setWidth(newWidth);
                const height = square ? newWidth : newWidth / 100 * 62.22
                setImageHeight(height)
            }
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [square]);

    return <Box
        ref={componentRef}
        sx={{ overflow: 'hidden', cursor: Boolean(onClick) ? 'pointer' : 'initial' }}
        onClick={onClick}
    >
        {imageURL ? <img
            alt={title}
            width={`${width}px`}
            height={`${imageHeight}px`}
            src={imageURL}
        /> : <Box
            width={`${width}px`}
            height={`${imageHeight}px`}
            sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <PhotoSizeSelectActualIcon fontSize={'large'}/>
        </Box>}
        <Box
            display={'flex'}
        >
            <Typography
                fontSize={'12px'}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
            >
                {title}
            </Typography>
        </Box>
    </Box>
}