import { FC, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { removeError } from "../../../features/ApiErrors/errorSlice";
import s from './ApiErrorDialog.module.css'
import { clearToken, clearUser } from "../../../features/user/userSlice";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const ApiErrorDialog: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: { pack: { common: lang } } } = useLanguage()
    const error = useAppSelector(state => state.errors.apiError ?? null)

    useEffect(() => {
        if (error?.code === 12) {
            dispatch(clearToken())
            dispatch(clearUser())
            dispatch(removeError())
        }
    }, [ dispatch, error ])

    return <Dialog maxWidth='xs' fullWidth open={Boolean(error) && error?.code !== 12} onClick={() => {
        dispatch(removeError())
    }}>
        <DialogTitle classes={{ root: s.alert }}>
            {error && (lang.errorCodeTitle + ': ' + error.code)}
        </DialogTitle>
        <DialogContent>
            {error && error.message}
        </DialogContent>
        <DialogActions>
            <Button autoFocus color={'error'} variant={'contained'}>
                OK
            </Button>
        </DialogActions>
    </Dialog>
}