import { FC, useEffect, useState } from "react";
import { AddPaymentForm } from "./AddPaymentForm";
import { PaymentMethodDropdown } from "./PaymentMethodDropdown";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useLanguage } from "../../../../features/localisation/useLanguage";
import { PaymentAccountField, PaymentMethodTemplate } from "../../../../features/paymentSystems/PaymentSystemModels";
import { usePaymentSystems } from "../../../../features/paymentSystems/usePaymentSystems";

interface AddPaymentProps {
    onDone?: () => void
    paymentMethodTemplate?: PaymentMethodTemplate
}

export const AddPayment: FC<AddPaymentProps> = ({onDone = () => {}, paymentMethodTemplate}) => {
    const { languagePack: {pack: {paymentSystems: lang}}} = useLanguage()
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethodTemplate>()
    const { isPaymentAccountAddLoading, addUserPaymentAccount } = usePaymentSystems()
    const [initLoad, setInitLoad] = useState<boolean>(true)
    
    useEffect(() => {
        if (initLoad) {
            if (Boolean(paymentMethodTemplate)) {
                setSelectedMethod(paymentMethodTemplate)
            }
            setInitLoad(false)
        }
    }, [initLoad, paymentMethodTemplate])

    const updateFieldsHandler = (fields: PaymentAccountField[]) => {
        if (selectedMethod) {
            const method: PaymentMethodTemplate = {
                ...selectedMethod,
                account_structure: {
                    ...selectedMethod.account_structure,
                    fields: fields
                }
            }
            setSelectedMethod(method)
        }
    }

    const addPaymentMethodHandler = () => {
        if (selectedMethod) {
            addUserPaymentAccount(selectedMethod.method_name, selectedMethod.id, selectedMethod.account_structure.fields, onDone)
        }
    }

    return <Stack rowGap={'20px'}>
        {!Boolean(paymentMethodTemplate) && <PaymentMethodDropdown onChange={(paymentMethod) => {
            setSelectedMethod(paymentMethod)
        }}/>}
        {selectedMethod && <AddPaymentForm paymentMethod={selectedMethod} onFieldsChanged={updateFieldsHandler}/>}

        <Box display={'flex'} justifyContent={'end'}>
            <Button
                disabled={!Boolean(selectedMethod) || isPaymentAccountAddLoading}
                variant={'contained'}
                onClick={addPaymentMethodHandler}
                endIcon={isPaymentAccountAddLoading && <CircularProgress size={16} sx={{color: "inherit"}}/>}
            >
                {lang.addBTN}
            </Button>
        </Box>
    </Stack>
}