import { Dialog, DialogContent } from "@mui/material";
import { FC, useState } from "react";
import { PaymentAccount } from "../../../../features/paymentSystems/PaymentSystemModels";
import { RequestPayment } from "./RequestPayment";

interface RequestPaymentDialogProps {
    close: () => void
    cashierPaymentAccount: PaymentAccount
}

export const RequestPaymentDialog: FC<RequestPaymentDialogProps> = ({close = () => {}, cashierPaymentAccount}) => {
    const [ opened, setOpened ] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        <DialogContent>
            <RequestPayment cashierPaymentAccount={cashierPaymentAccount} onDone={pendingClose}/>
        </DialogContent>
    </Dialog>
}