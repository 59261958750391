import { FC, useEffect, useState } from "react";
import clsx from 'clsx';
import { getPagesAfterCurrent, getPagesBeforeCurrent } from "./paginatorFuncs";
import s from './Paginator.module.css'
import { Paper, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface PaginatorProps {
    totalPages: number
    currentPage: number
}

export const Paginator: FC<PaginatorProps> = ({ totalPages, currentPage }) => {
    const [ beforePages, setBeforePages ] = useState<number[]>([])
    const [ afterPages, setAfterPages ] = useState<number[]>([])

    const navigate = useNavigate();
    const location = useLocation();

    const smallScreen = useMediaQuery('(max-width:660px)')

    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        setBeforePages(currentPage > 1 ? getPagesBeforeCurrent(currentPage, smallScreen ? 1 : undefined) : [])
        setAfterPages(currentPage < totalPages ? getPagesAfterCurrent(currentPage, totalPages, smallScreen ? 1 : undefined) : [])
    }, [currentPage, smallScreen, totalPages])

    const changePage = (page: number) => {
        searchParams.set('page', page.toString());

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return totalPages > 1 ? <div className={s.container}>
        {/* First page here */}
        {currentPage > 1 && <Paper onClick={() => {changePage(1)}} className={clsx(s.pageBox, s.rightMargin, s.clickable)}>1</Paper>}

        {/* pages before current */}
        {beforePages.map(p => <Paper key={'page_' + p} onClick={() => {changePage(p)}} className={clsx(s.pageBox, s.clickable)}>{p}</Paper>)}

        {/* Current page */}
        <Paper variant={'outlined'} className={clsx(s.pageBox, s.horizontalMargin)}>{currentPage}</Paper>

        {/* pages after current */}
        {afterPages.map(p => <Paper key={'page_' + p} onClick={() => {changePage(p)}} className={clsx(s.pageBox, s.clickable)}>{p}</Paper>)}

        {/* Last page here */}
        {currentPage < totalPages && <Paper onClick={() => {changePage(totalPages)}} className={clsx(s.pageBox, s.leftMargin, s.clickable)}>{totalPages}</Paper>}
    </div> : <></>
}