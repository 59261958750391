import React, { FC } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../../../assets/ganamos-logo.png";
import { DesktopTopBarMenu } from "../desktop_topbar_menu/DesktopTopBarMenu";
import { LoginForm } from "../../common/login/LoginForm";
import { useUser } from "../../../features/user/useUser";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from "@mui/material/IconButton";
import LanguageSelector from "../../common/lang/LanguageSelector";

export const TopBarDesktop: FC = () => {
    const {token} = useUser()

    return (
        <Box
            sx={{ backgroundColor: 'var(--dark-bg-color)' }}
            // sx={{ backgroundColor: '#b05e00' }}
            minHeight={'55px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={'30px'}
        >
            {/* ------- Logo ------- */}
            <Box mt={'10px'}>
                <Link to={'/'}>
                    <img src={logo} alt={'logo'} height={'33px'}/>
                </Link>
            </Box>

            {/* ------- Middle menu section ------- */}
            <Box height={'100%'}>
                <DesktopTopBarMenu/>
            </Box>

            {/* ------- Right section ------- */}
            <Box display={'flex'} alignItems={'center'} columnGap={'10px'}>
                {!Boolean(token) ? <LoginForm/> : <Link to={'/dashboard'}>
                    <IconButton>
                        <AccountCircleIcon fontSize={'large'}/>
                    </IconButton>
                </Link>}

                <LanguageSelector/>
            </Box>
        </Box>
    )
}