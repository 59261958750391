import Box from "@mui/material/Box"
import { FC, ReactNode } from "react";
import Typography from "@mui/material/Typography";

interface SideMenuSectionMenuProps {
    children?: ReactNode
    title: string
}

export const SideMenuSectionMenu: FC<SideMenuSectionMenuProps> = ({title, children}) => {
    return <Box
        display={'flex'}
        flexDirection={'column'}
        rowGap={'8px'}
    >
        <Typography fontSize={'13px'} px={'20px'} py={'8px'} sx={{opacity: '40%'}}>{title}</Typography>
        {children}
    </Box>
}