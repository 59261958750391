import { User } from "./user";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import axios from "axios";
import Config, { getApiHost } from "../../config";
import { ApiResponse } from "../apiResponseModels";
import {
    clearToken,
    clearUser,
    setChangePasswordLoading,
    setLogInLoading,
    setToken,
    setUser,
    setUserInfoUpdateLoading
} from "./userSlice";
import { GetBalanceResponse, iUseLogin, LoginResponse, UserInfoUpdateResponse } from "./useUserInterfaces";
import { useLanguage } from "../localisation/useLanguage";
import { AxiosError } from "axios";
import { setOutcomeGames } from "../play_games/playGamesSlice";


export const useUser = (): iUseLogin => {
    const { setLocalizedError, handleNetworkErrors } = useLanguage()
    const user = useAppSelector(state => state.user.user ?? null)
    const token = useAppSelector(state => state.user.token ?? null)
    const isLogInLoading = useAppSelector(state => state.user.isLogInLoading ?? false)
    const isUserInfoUpdateLoading = useAppSelector(state => state.user.isUserInfoUpdateLoading ?? false)
    const isChangePasswordLoading = useAppSelector(state => state.user.isChangePasswordLoading ?? false)
    const dispatch = useAppDispatch()
    const apiURL = getApiHost()

    const logIn = useCallback((username: string, password: string, onSuccess = () => {}) => {
        if (apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'Login')
            data.append('username', username)
            data.append('password', password)

            dispatch(setLogInLoading(true))

            axios.post<LoginResponse>(apiURL, data)
                .then(response => {
                    const { success, error, token, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                        if (token) {
                            dispatch(setToken(token))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setLogInLoading(false))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError])

    const updateUserInfo = useCallback((newUserInfo: User) => {
        if (token && user && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdateUserInformation')
            data.append('token', token)
            if ((newUserInfo.user_email && newUserInfo.user_email.trim() !== '') || user.user_email) data.append('useremail', newUserInfo.user_email ?? user.user_email ?? '')
            data.append('userphone', newUserInfo.user_phone ?? '')
            data.append('userfirstname', newUserInfo.user_firstname ?? '')
            data.append('userlastname', newUserInfo.user_lastname ?? '')

            dispatch(setUserInfoUpdateLoading(true))

            axios.post<UserInfoUpdateResponse>(apiURL, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setUserInfoUpdateLoading(false))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const logOut = useCallback(() => {
        if (token && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'logout')
            data.append('token', token)

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        dispatch(clearUser())
                        dispatch(clearToken())
                        window.location.reload()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
        }
    }, [token, apiURL, dispatch, setLocalizedError, handleNetworkErrors])

    const changePassword = useCallback((oldPassword: string, newPassword: string, onSuccess: () => void = () => {}) => {
        if (token && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ChangePassword')
            data.append('token', token)

            data.append('password', oldPassword)
            data.append('newpassword', newPassword)

            dispatch(setChangePasswordLoading(true))

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setChangePasswordLoading(false))
                })
        }
    }, [token, apiURL, dispatch, setLocalizedError, handleNetworkErrors])

    const refreshUserInfo = useCallback(() => {
        if (token && user && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetUserInformation')
            data.append('token', token)

            axios.post<UserInfoUpdateResponse>(apiURL, data)
                .then(response => {
                    const { success, error, user } = response.data
                    if (success) {
                        if (user) {
                            dispatch(setUser(user))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const refreshUserBalance = useCallback(() => {
        if (token && user && apiURL) {
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetBalance')
            data.append('token', token)

            axios.post<GetBalanceResponse>(apiURL, data)
                .then(response => {
                    const { success, error, balance, bonus_balance, freespins } = response.data
                    if (success) {
                        if (balance !== undefined && bonus_balance !== undefined && freespins !== undefined) {
                            const updatedUser: User = {
                                ...user,
                                user_balance: balance,
                                user_bonus_balance: bonus_balance,
                                user_freespins: freespins
                            }
                            dispatch(setUser(updatedUser))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setOutcomeGames([]))
                    handleNetworkErrors(error)
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    return {
        user,
        token,
        logIn,
        updateUserInfo,
        refreshUserInfo,
        refreshUserBalance,
        isLogInLoading,
        isUserInfoUpdateLoading,
        logOut,
        changePassword,
        isChangePasswordLoading
    }
}