import {
    AddPaymentAccountsResponse,
    GetPaymentMethodsResponse,
    iUsePaymentSystems,
    PaymentAccountField, Proof, RequestPayoutResponse,
    ShowPaymentAccountsResponse
} from "./PaymentSystemModels";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback } from "react";
import { useLanguage } from "../localisation/useLanguage";
import {
    addUserPaymentAccountToList, setBonusRequestLoading,
    setPaymentAccountAddLoading,
    setPaymentAccountsLoading,
    setPaymentMethodsLoading, setPaymentRequestLoading, setPayoutAccountsLoading, setRequestPayoutLoading,
    setUserPaymentAccounts,
    setUserPaymentMethods, setUserPayoutAccounts
} from "./paymentSystemsSlice";
import axios from "axios";
import Config, { getApiHost } from "../../config";
import { updateUserBalance } from "../user/userSlice";
import { ApiResponse } from "../apiResponseModels";

export const usePaymentSystems = (): iUsePaymentSystems => {
    const { setLocalizedError } = useLanguage()
    const token = useAppSelector(state => state.user.token ?? null)
    const dispatch = useAppDispatch()
    const apiURL = getApiHost()

    const {
        userPaymentAccounts,
        isPaymentAccountsLoading,
        paymentMethods,
        isPaymentMethodsLoading,
        isPaymentAccountAddLoading,
        userPayoutAccounts,
        isPayoutAccountsLoading,
        isRequestPayoutLoading,
        unfilteredPaymentMethods,
        isBonusRequestLoading,
        isPaymentRequestLoading
    } = useAppSelector(state => state.paymentSystems)

    const getUserPaymentAccounts = useCallback(() => {
        if (token && apiURL) {
            dispatch(setPaymentAccountsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowMyPaymentAccounts')
            data.append('token', token)

            axios.post<ShowPaymentAccountsResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data: userPaymentAccounts } = response.data
                    if (success) {
                        if (userPaymentAccounts) {
                            dispatch(setUserPaymentAccounts(userPaymentAccounts))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setUserPaymentAccounts([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .finally(() => {
                    dispatch(setPaymentAccountsLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token])

    const getPaymentMethods = useCallback(() => {
        if (token && apiURL) {
            dispatch(setPaymentMethodsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetPaymentMethods')
            data.append('token', token)

            axios.post<GetPaymentMethodsResponse>(apiURL, data)
                .then(response => {
                    const { success, error, payments } = response.data
                    if (success) {
                        if (payments) {
                            dispatch(setUserPaymentMethods(payments))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setUserPaymentMethods([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .finally(() => {
                    dispatch(setPaymentMethodsLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token])

    const addUserPaymentAccount = useCallback((paymentMethodName: string, paymentMethodID: number, paymentAccountFields: PaymentAccountField[], onSuccess = () => {}) => {
        if (token && apiURL) {
            dispatch(setPaymentAccountAddLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'AddPaymentAccount')
            data.append('token', token)

            const params = JSON.stringify({
                payment_method: paymentMethodName,
                fields: paymentAccountFields
            }, null, 0)

            data.append('method_id', paymentMethodID.toString())
            data.append('params', params)

            axios.post<AddPaymentAccountsResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data: paymentAccount } = response.data
                    if (success) {
                        if (paymentAccount) {
                            dispatch(addUserPaymentAccountToList(paymentAccount))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .finally(() => {
                    dispatch(setPaymentAccountAddLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token])

    const getPayoutAccounts = useCallback(() => {
        if (token && apiURL) {
            dispatch(setPayoutAccountsLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowPayoutAccounts')
            data.append('token', token)

            axios.post<ShowPaymentAccountsResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data: payoutAccounts } = response.data
                    if (success) {
                        if (payoutAccounts) {
                            dispatch(setUserPayoutAccounts(payoutAccounts))
                        }
                    }
                    if (error) {
                        if (error.code === 2) {
                            dispatch(setUserPayoutAccounts([]))
                        } else {
                            setLocalizedError(error)
                        }
                    }
                })
                .finally(() => {
                    dispatch(setPayoutAccountsLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token])

    const requestPayout = useCallback((paymentMethodID: number, amount: number, onSuccess = () => {}) => {
        if (token && apiURL) {
            dispatch(setRequestPayoutLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'RequestPayout')
            data.append('token', token)

            data.append('method_id', paymentMethodID.toString())
            data.append('amount', amount.toString())

            axios.post<RequestPayoutResponse>(apiURL, data)
                .then(response => {
                    const { success, error, new_balance } = response.data
                    if (success) {
                        if (new_balance !== undefined) {
                            dispatch(updateUserBalance(new_balance))
                            onSuccess()
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .finally(() => {
                    dispatch(setRequestPayoutLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token])

    const requestBonus = useCallback((promoCode: string, moneyAmount: number, onSuccess = () => {}) => {
        if (token && apiURL) {
            dispatch(setBonusRequestLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'RequestBonus')
            data.append('token', token)

            data.append('promocode', promoCode)
            data.append('amount', moneyAmount.toString())

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .finally(() => {
                    dispatch(setBonusRequestLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token ])

    const requestPayment = useCallback((method_id: number, amount: number, proof: Proof, promoCode?: string, onSuccess = () => {}) => {
        if (token && apiURL) {
            const params = JSON.stringify(proof, null, 0)

            dispatch(setPaymentRequestLoading(true))
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'RequestPayment')
            data.append('token', token)

            data.append('method_id', method_id.toString())
            data.append('amount', amount.toString())
            data.append('params', params)
            if (promoCode && promoCode.trim() !== '') {
                data.append('promocode', promoCode)
            }

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .finally(() => {
                    dispatch(setPaymentRequestLoading(false))
                })
        }
    }, [apiURL, dispatch, setLocalizedError, token])

    return {
        userPaymentAccounts,
        isPaymentAccountsLoading,
        getUserPaymentAccounts,
        paymentMethods,
        isPaymentMethodsLoading,
        getPaymentMethods,
        isPaymentAccountAddLoading,
        addUserPaymentAccount,
        userPayoutAccounts,
        isPayoutAccountsLoading,
        isRequestPayoutLoading,
        getPayoutAccounts,
        requestPayout,
        unfilteredPaymentMethods,
        isBonusRequestLoading,
        requestBonus,
        requestPayment,
        isPaymentRequestLoading
    }
}