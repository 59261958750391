import Config from "../config";
import { User } from "../features/user/user";

const TOKEN = Config.localStoragePrefix + 'token'
const USER = Config.localStoragePrefix + 'user'

class UserStorage {
    static getUser () : User | null {
        const s = localStorage.getItem(USER)
        if (s !== null) return JSON.parse(s) as User
        return null
    }

    static setUser (user: User) {
        localStorage.setItem(USER, JSON.stringify(user))
        console.log('----------------------- attempt to set user ---------------------', JSON.stringify(user))
    }

    static removeUser () {
        localStorage.removeItem(USER)
        console.log('----------------------- attempt to remove user ---------------------')
    }

    static getToken () : string | null {
        return localStorage.getItem(TOKEN)
    }

    static setToken (token: string) {
        localStorage.setItem(TOKEN, token)
        console.log('----------------------- attempt to set token ---------------------', token)
    }

    static removeToken () {
        localStorage.removeItem(TOKEN)
        console.log('----------------------- attempt to remove token ---------------------')
    }
}

export default UserStorage