import { FC } from "react";
import { Box } from "@mui/material";
import { useTransactions } from "../../features/transactions/useTransactions";
import { TransactionItemCard } from "./TransactionItemCard";
import { useUser } from "../../features/user/useUser";

export const TransactionListCardStyle: FC = () => {
    const { transactions } = useTransactions()
    const { user } = useUser()

    return <Box display={'flex'} flexDirection={'column'} height={'100%'} overflow={'auto'} width={'100%'} rowGap={'10px'}>
        {transactions.map(transaction => <TransactionItemCard currency={user?.user_currency} key={transaction.uuid} transactionItem={transaction}/>)}
    </Box>
}