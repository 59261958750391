import { FC, useEffect, useState } from "react";
import { DashboardContentBox } from "../../../components/dashboard/DashboardContentBox";
import { CenteredList } from "../../../components/dashboard/CenteredList";
import { Avatar, Box, LinearProgress, Paper, Stack } from "@mui/material";
import { usePaymentSystems } from "../../../features/paymentSystems/usePaymentSystems";
import { clearPaymentSystems } from "../../../features/paymentSystems/paymentSystemsSlice";
import { useAppDispatch } from "../../../store";
import PaymentsIcon from "@mui/icons-material/Payments";
import Typography from "@mui/material/Typography";
import { PaymentAccount } from "../../../features/paymentSystems/PaymentSystemModels";
import { RequestPaymentDialog } from "./request_payment/RequestPaymentDialog";

export const DepositMoney: FC = () => {
    const dispatch = useAppDispatch()
    const { userPayoutAccounts, isPayoutAccountsLoading, getPayoutAccounts } = usePaymentSystems()
    const [ initLoad, setInitLoad ] = useState<boolean>(true)
    const [showRequestPayment, setShowRequestPayment] = useState<PaymentAccount|null>(null)

    useEffect(() => {
        if (initLoad) {
            getPayoutAccounts()
            setInitLoad(false)
        }
    }, [getPayoutAccounts, initLoad])

    useEffect(() => {
        return () => {
            dispatch(clearPaymentSystems())
        }
    }, [ dispatch ])

    return <>
        <DashboardContentBox>
            <CenteredList>
                {isPayoutAccountsLoading && <LinearProgress sx={{ width: '100%' }}/>}

                {userPayoutAccounts?.map(payoutAccount => <Paper
                    onClick={() => setShowRequestPayment(payoutAccount)}
                    key={`payout-method-id-${payoutAccount.id}`} sx={{ width: '100%' }}>
                    <Box padding={'10px'} sx={{ cursor: 'pointer' }}>
                        <Stack direction={'row'} alignItems={'center'} columnGap={'18px'}>
                            <Avatar
                                alt={`${payoutAccount.method_name} payment method icon`}
                                src={payoutAccount.pic_url}
                                // src={config.hostDomain + payoutAccount.pic_url}
                                sx={{ width: 52, height: 52 }}
                            >
                                <PaymentsIcon fontSize={'small'}/>
                            </Avatar>
                            <Typography fontSize={18} fontWeight={'500'}>
                                {payoutAccount.method_name}
                            </Typography>
                        </Stack>
                    </Box>
                </Paper>)}
            </CenteredList>
        </DashboardContentBox>

        {showRequestPayment && <RequestPaymentDialog cashierPaymentAccount={showRequestPayment} close={() => setShowRequestPayment(null)}/>}
    </>
}