import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { BottomMobileMenuItem } from "./BottomMobileMenuItem";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';

interface BottomMobileMenuProps {
    onHeightChanged?: (height: number) => void
}

export const BottomMobileMenu: FC<BottomMobileMenuProps> = ({onHeightChanged = () => {}}) => {
    const [menuHeight, setMenuHeight] = useState<number>(0);
    const menuRef = useRef<HTMLDivElement>(null);

    const updateHeight = useCallback(() => {
        if (menuRef.current) {
            const newHeight = menuRef.current.offsetHeight
            if (menuHeight !== newHeight) {
                setMenuHeight(newHeight)
                onHeightChanged(newHeight)
            }
        }
    }, [onHeightChanged, menuHeight])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    return <Box
        ref={menuRef}
        position={'fixed'}
        bottom={0}
        left={0}
        right={0}
        sx={{
            backgroundColor: 'var(--dark-2)',
        }}
        display={'flex'}
    >
        <BottomMobileMenuItem icon={HomeOutlinedIcon} title={'Inicio'} link={'/'} isActive={true}/>
        <BottomMobileMenuItem icon={SportsSoccerOutlinedIcon} title={'Deporte'} link={'/'} />
        <BottomMobileMenuItem icon={OndemandVideoOutlinedIcon} title={'Apuestes En Vivo'} link={'/'} />
        <BottomMobileMenuItem icon={CasinoOutlinedIcon} title={'Casino'} link={'/'} />
        <BottomMobileMenuItem icon={StyleOutlinedIcon} title={'Casino En Vivo'} link={'/'} />
    </Box>
}