import React, { FC } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

interface SideMenuItemProps {
    title: string
    icon: React.ElementType
    iconColor: string
    isFirst?: boolean
    isLast?: boolean
    isActive?: boolean
    onClick?: () => void
}

export const SideMenuItem: FC<SideMenuItemProps> = ({title, icon,  isActive = false, iconColor, isFirst = false, isLast = false, onClick = () =>{}}) => {
    return <Box
        onClick={onClick}
        borderTop={isFirst ? 'none' : 'solid 1px rgba(255, 255, 255, 0.08)'}
        borderBottom={isLast ? 'none' : 'solid 1px rgba(0, 0, 0, 0.3)'}
        padding={'14px'}
        display={'flex'}
        alignItems={'center'}
        columnGap={'18px'}
        sx={{
            backgroundColor: isActive ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0)',
            cursor: 'pointer'
        }}
    >
        <Box
            color={iconColor}
            mb={'-4px'}
        >
            {React.createElement(icon)}
        </Box>
        <Typography color={'#fff'}>
            {title}
        </Typography>
    </Box>
}