import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

interface DashboardContentBoxProps {
    children?: string|ReactNode|null
}

export const DashboardContentBox: FC<DashboardContentBoxProps> = ({children}) => <Box
    display={'flex'}
    flexDirection={'column'}
    gap={'20px'}
    padding={'20px'}
    height={'calc(100vh - 64px - 40px - 24px)'}
    alignItems={'center'}
    width={'100%'}
>{children}</Box>