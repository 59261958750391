import { FC, ReactNode, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { TopBar } from "../top_bar/TopBar";
import { BottomMobileMenu } from "../BottomMobileMenu/BottomMobileMenu";
import { Slider } from "../slider/Slider";

interface PageLayoutProps {
    children: ReactNode
}

export const PageLayout: FC<PageLayoutProps> = ({children}) => {
    const mobileScreens = useMediaQuery('only screen and (max-width: 768px)')
    const [topBarHeight, setTopBarHeight] = useState<number>(0)
    const [bottomMenuHeight, setBottomMenuHeight] = useState<number>(0)

    return <Box
        sx={{backgroundColor: 'var(--dark-bg-color)'}}
        height={'100vh'}
        width={'100vw'}
        overflow={'auto'}
        pt={mobileScreens ? `${topBarHeight}px` : 0}
        pb={mobileScreens ? `${bottomMenuHeight}px` : 0}
        display={'flex'}
        flexDirection={'column'}
        rowGap={'15px'}
    >
        <TopBar onHeightChanged={setTopBarHeight}/>
        {mobileScreens && <BottomMobileMenu onHeightChanged={setBottomMenuHeight}/>}
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
        >
            <Slider/>
            {children}
        </Box>
    </Box>
}