import { GameObj, PlayGamesLoader, PlayGamesState, GameProvider } from "./playGamesModels";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderAction } from "../common_models";
import { OutcomeGameSession } from "../games/game";
import { groupGamesByProviders } from "./funcs";

const initialState: PlayGamesState = {
    providersOutcome: null,
    providersFiltered: null,
    selectedProvider: null,
    outcomeGames: null,
    groupedGames: null,
    gameSession: {
        SessionId: null,
        SessionUrl: null
    },

    isProvidersOutcomeLoading: false,
    isOutcomeGameLoading: false,
    isGameSessionLoading:  false
}

export const PlayGamesSlice = createSlice({
    name: 'playGames',
    initialState,
    reducers: {
        resetPlayGamesSlice: (state) => {
            state.providersOutcome = null
            state.providersFiltered = null
            state.selectedProvider = null
            state.outcomeGames = null
            state.groupedGames = null
            state.gameSession = {
                SessionId: null,
                SessionUrl: null
            }

            state.isProvidersOutcomeLoading = false
            state.isOutcomeGameLoading = false
            state.isGameSessionLoading = false
        },
        setPlayGamesLoader: (state, action: PayloadAction<LoaderAction<PlayGamesLoader>>) => {
            switch (action.payload.loader) {
                case PlayGamesLoader.isProvidersOutcomeLoading:
                    state.isProvidersOutcomeLoading = action.payload.isLoading
                    break
                case PlayGamesLoader.isOutcomeGameLoading:
                    state.isOutcomeGameLoading = action.payload.isLoading
                    break
                case PlayGamesLoader.isGameSessionLoading:
                    state.isGameSessionLoading = action.payload.isLoading
                    break
            }
        },
        setProvidersOutcome: (state, action: PayloadAction<GameProvider[]>) => {
            state.providersOutcome = action.payload
        },
        setSelectedProviderName: (state, action: PayloadAction<string|null>) => {
            state.selectedProvider = action.payload
        },
        setOutcomeGames: (state, action: PayloadAction<GameObj[]|null>) => {
            state.outcomeGames = action.payload
            if (state.providersOutcome && action.payload) {
                state.groupedGames = groupGamesByProviders(state.providersOutcome, action.payload)
            }
        },
        setGameSession: (state, action: PayloadAction<OutcomeGameSession>) => {
            state.gameSession = action.payload
        }
    }
})

export const {
    resetPlayGamesSlice,
    setPlayGamesLoader,
    setProvidersOutcome,
    setSelectedProviderName,
    setOutcomeGames,
    setGameSession
} = PlayGamesSlice.actions