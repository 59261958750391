import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC } from "react";
import { useGamesHistory } from "../../../features/gameHistory/useGamesHistory";
import { useUser } from "../../../features/user/useUser";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const GameHistoryTable: FC = () => {
    const { languagePack: {pack: { gamesHistory }}} = useLanguage()
    const { gameHistory } = useGamesHistory()
    const { user } = useUser()

    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{gamesHistory.providerTableHeader}</TableCell>
                    <TableCell align="right">{gamesHistory.gameTableHeader}</TableCell>
                    <TableCell align="right">{gamesHistory.betAmountTableHeader}</TableCell>
                    <TableCell align="right">{gamesHistory.winAmountTableHeader}</TableCell>
                    <TableCell align="right">{gamesHistory.dateTimeTableHeader}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {gameHistory && gameHistory.map((game, index) => (
                    <TableRow
                        key={game.name + '-gt-' + index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {game.sectionid}
                        </TableCell>
                        <TableCell align="right">{game.name}</TableCell>
                        <TableCell
                            align="right">{game.withdraw_sum ? convertCentsToCurrencyString(game.withdraw_sum) : '--'}{user && game.withdraw_sum && (' ' + user?.user_currency)}</TableCell>
                        <TableCell
                            align="right">{game.deposit_sum ? convertCentsToCurrencyString(game.deposit_sum) : '--'}{(user && game.deposit_sum) ? (' ' + user?.user_currency) : ''}</TableCell>
                        <TableCell align="right">{game.time}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}