import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { get } from "lodash";

export const ContainedIconButton = styled(IconButton)(({ theme, color }) => ({
    backgroundColor: get(theme, `palette.${color}.main`, theme.palette.grey[300]),
    color: get(theme, `palette.${color}.contrastText`, theme.palette.getContrastText(get(theme, `palette.${color}.main`, theme.palette.grey[300])))!,
    '&:hover': {
        backgroundColor: get(theme, `palette.${color}.dark`, theme.palette.grey[500]),
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.action.disabled,
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
}));
