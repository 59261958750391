import { PageLayout } from "../../components/ganamos_layout/layout/PageLayout";
import { Box, LinearProgress } from "@mui/material";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { useEffect, useState } from "react";
import { ContentSection } from "../../components/ganamos_layout/content_section/ContentSection";
import Grid from "@mui/material/Unstable_Grid2";
import { GameCard } from "../../components/ganamos_layout/GameCard";
import Config from "../../config";

export const CasinoPage = () => {
    const { getProviders, getGames, isProvidersOutcomeLoading, isOutcomeGameLoading, groupedGames, providersOutcome } = usePlayGames()
    const [initLoad, setInitLoad] = useState<boolean>(true)

    useEffect(() => {
        if (initLoad) {
            if (!Boolean(groupedGames)) {
                if (!Boolean(providersOutcome)) {
                    getProviders(() => {
                        getGames()
                    })
                } else {
                    getGames()
                }
            }
            setInitLoad(false)
        }
    }, [getGames, getProviders, groupedGames, initLoad, providersOutcome])

    return <PageLayout>
        <Box display={'flex'} flexDirection={'column'} rowGap={'15px'} px={'30px'}>
            {(isProvidersOutcomeLoading || isOutcomeGameLoading) && <LinearProgress/>}

            {groupedGames && groupedGames.map(group => {
                const groupID = `provider-group-${group.provider.provider_name}`
                return <ContentSection
                    key={groupID}
                    id={groupID}
                    sectionTitle={group.provider.title ?? group.provider.provider_name}
                    linkToFullSection={`/casino?providerID=${group.provider.id}`}
                    imageIconUrl={group.provider.logo ?? undefined}
                >
                    <Grid container rowSpacing={'15px'} columnSpacing={'10px'} columns={8}>
                        {group.games.slice(0, 8).map(game => {
                            return <Grid xs={2} md={1} key={`game-${game.Name}`}>
                                <GameCard imageURL={game.Pic ? `${Config.hostDomain}${game.Pic}` : undefined} title={game.Name}/>
                            </Grid>
                        })}
                    </Grid>
                </ContentSection>
            })}
        </Box>
    </PageLayout>
}